'use client';

import { useEffect } from 'react';

import { Button, Container, Heading } from '@/components';
import { useRouter } from 'next/navigation';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const router = useRouter();
  useEffect(() => {
    // Log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  const handleReturnToHome = () => {
    router.push('/');
  };

  return (
    <main>
      <section className={'page_not_found'}>
        <Container className="page_not_found__content">
          <div className="page_not_found__heading">
            <Heading className="heading" level={1}>
              500
            </Heading>
            <p className="heading__descrip">Opps! Something went wrong.</p>
          </div>
          <p className="page_not_found__content__description">
            There was an error on our server.
            <br />
            If the error persists, please reach out to our support team.
          </p>
          <div className="page_not_found__form">
            <Button
              className="page_not_found__form_action_return"
              color="primary"
              onClick={handleReturnToHome}
            >
              Return to Home
            </Button>
            <Button
              color="link"
              onClick={
                // Attempt to recover by trying to re-render the segment
                () => reset()
              }
              size="md"
              style={{ marginTop: '20px', textDecoration: 'underline' }}
            >
              Try again
            </Button>
          </div>
        </Container>
      </section>
    </main>
  );
}
